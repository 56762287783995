
import { Component, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import GPeoplePicker from '@/components/gsk-components/GskPeoplePicker.vue';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { RoleNames, Roles, RouteNames } from '@/constants';
import { ProjectUser } from '@/types/projects.types';
import { AnalyticsUser, ClickData } from '@/analytics';
import { UserModule } from '@/store/modules/user.module';
import GAnalytics from '@/components/GAnalytics';
import MemberPermissions from '@/components/MemberPermissions.vue';
import TeamPermissions from '@/components/TeamPermissions.vue';
import { ProjectTeam } from '@/types/teams.types';
import {EnumsModule} from "@/store/modules/enums.module";
import { openSnackbar } from '@/utils/components';

interface UserRole extends AnalyticsUser {
  roleId: Roles;
  roleName?: RoleNames;
}

interface AddUser {
  projectId: number;
  user: UserRole;
}

interface RemoveUser {
  projectId: number;
  user: UserRole;
}

@Component({
  components: {
    FullScreenForm,
    GButton,
    GPeoplePicker,
    GAnalytics,
    MemberPermissions,
    TeamPermissions,
  },
})
export default class ProjectPermissionsView extends Vue {
  private owners: ProjectUser[] = [];
  private teams: ProjectTeam[] = [];
  private initialState: string = JSON.stringify(this.owners);
  private initialTeamState: string = JSON.stringify(this.teams);
  private allowedRoles:{id:number, name:string}[] = [EnumsModule.enums.role.OWNER, EnumsModule.enums.role.PROJECTVIEWER];
  private modalClosed = false;


  get isLoading() {
    return ProjectDetailsModule.projectPermissionsLoading;
  }

  async save(): Promise<void> {
    this.$log('line 42', this.owners);
    await ProjectDetailsModule.updateProjectPermissions({
      projectId: this.$route.params.id,
      skipGet: this.removedSelf,
      users: this.owners,
      teams: this.teams,
    });
    if (this.removedSelf) {
      // will 403 if user gets redirected to project they don't have permission to view
      this.$router.replace(this.projectsRoute);
    } else {
      openSnackbar.call(this, 'Permissions saved!');
      this.$router.push(this.projectRoute);
    }
  }

  get analyticsData(): ClickData {
    return {
      clickTarget: 'project-permissions-save',
      projectName: ProjectDetailsModule.projectDetails.projectName,
      projectId: ProjectDetailsModule.projectDetails.projectId,
      removedUsers: this.userDiff.removed.length,
      addedUsers: this.userDiff.added.length,
    };
  }

  get userDiff() {
    const originalOwners: ProjectUser[] = JSON.parse(this.initialState);
    const ogSet = new Set(originalOwners.map(o => o.mudId));
    const currentSet = new Set(this.owners.map(o => o.mudId));
    const formatUser = (u: ProjectUser): AddUser | RemoveUser => ({
      projectId: +this.$route.params.id,
      user: {
        roleId: Roles.Owner,
        roleName: RoleNames.Owner,
        mudId: u.mudId,
        email: u.email,
      },
    });

    // in current, but not in old === added
    const added: AddUser[] = this.owners.filter(o => !ogSet.has(o.mudId)).map(formatUser);

    // in old, but not in current === removed
    const removed: RemoveUser[] = originalOwners
      .filter(o => !currentSet.has(o.mudId))
      .map(formatUser);

    return {
      added,
      removed,
    };
  }

  deleteMember(memberList: ProjectUser[]) {
    this.owners = memberList;
  }

  addMembers(memberList: ProjectUser[]) {
    this.$set(this, 'owners', memberList);
  }

  addTeam(team: ProjectTeam) {
    if(!this.teams.find(ref => ref.teamId === team.teamId)) {
      this.teams.push(team);
    }
  }

  deleteTeam(teamList: ProjectTeam[]) {
    this.teams = teamList;
  }

  updateTeam(updatedTeam: ProjectTeam) {
    this.teams = this.teams.map(t => t.teamId == updatedTeam.teamId ? {...updatedTeam} : t);
  }

  get removedSelf(): boolean {
    return this.userDiff.removed.some(removal => removal.user.mudId === this.self.mudId) && !this.teams.some(t => t.teamMembers.some(tm => tm.mudId === this.self.mudId));
  }

  get self() {
    return UserModule.user;
  }

  close() {
    this.modalClosed = true;
  }

  get disabled(): boolean {
    return this.isLoading;
  }

  get hasOwners() {
    return !!(
        this.owners.find( user => user.roleId === Roles.Owner)  ||
        this.teams.find( team =>  team.roleId === Roles.Owner)
    );
  }

  get canSave() {
    return (
      this.hasOwners &&
      (this.initialState !== JSON.stringify(this.owners) ||
        this.initialTeamState !== JSON.stringify(this.teams))
    );
  }

  get projectRoute(): RawLocation {
    const { id } = this.$route.params;
    return { name: RouteNames.ProjectDetails, params: { id } };
  }

  get projectsRoute(): RawLocation {
    return { name: RouteNames.ProjectsList };
  }

  async created() {
    if (ProjectDetailsModule.projectDetails.projectId === +this.$route.params.id) {
      this.$log('populated form without loading fresh data');
    } else {
      this.$log('populated form by loading fresh data');
      await ProjectDetailsModule.getProject(this.$route.params.id);
    }

    this.owners = JSON.parse(JSON.stringify(ProjectDetailsModule.projectDetails.projectUsers));
    this.teams = JSON.parse(JSON.stringify(ProjectDetailsModule.projectDetails.teams));
    this.initialState = JSON.stringify(this.owners);
    this.initialTeamState = JSON.stringify(this.teams);
  }
}
